<template>
    <div class="tool-html-frame">
      <div class="loading" :class="{loaded: !loading}"> 
        <div class="describe"  :loading="true" >正在加载{{name}}</div>
      </div>
    
       <!-- <div style="height:100%;overflow: auto;" v-loading="loading">
        <iframe ref="iframe" :src="src" width="100%" height="100%" frameborder="0"></iframe>
      </div>
       -->
        <iframe id="hframe" :src="url"  frameborder="0" :style="'width:'+ width +'; height:' + height"></iframe> 
        <!-- <iframe id="hframe" :src="url"  frameborder="0" :style="'transform: scaleX(0.92) ; transform-origin: 0 0; width:'+ width +'; height:' + height"></iframe>  -->
             <!-- <iframe id="hframe" :src="url" frameborder="1" style="width: 1500px; transform: scale(0.5 ) translateX(-500px) translateY(-300px); height:600px"></iframe>  -->
    
      
    </div>
</template>

<script>

export default {
  name: 'HtmlFrame',
  props: {
    width:  {
      typeof: [String],
      default: "1300px",
    },
    height:  {
      typeof: [String],
      default: "1500px",
    },
    url: {
      typeof: [String],
      require: true,
    },
    name: {
      typeof: [String],
      require: true,
    },
    
  },
   data() {
    return {
      loading: true,
    };
  },
  mounted() {
    console.log("  tool url :", this.url)
    const iframe = document.querySelector('#hframe')
    // 处理兼容行问题
    // if (iframe.attachEvent) {
    //   iframe.attachEvent('onload', function () {
    //     // iframe加载完毕以后执行操作
    //     // console.log('attachEvent iframe已加载完毕')
    //     this.style.height = this.contentWindow.document.documentElement.scrollHeight + 50+ "px"
    //     this.style.width = this.contentWindow.document.documentElement.scrollWidth + 50+ "px"
    //   })
    // } else {
      let me = this
      iframe.onload = function () {
        // console.log("  this.loading ", me.loading)
        me.loading = false
        // iframe加载完毕以后执行操作
        // console.log('onload iframe已加载完毕')
        // this.style.height = this.contentWindow.document.documentElement.scrollHeight + 50+ "px"
        // this.style.width = this.contentWindow.document.documentElement.scrollWidth + 50+ "px"
        // console.log("  this.loading ", me.loading)

      }
    // }    
    // console.log("  this.width "+this.width)
    // console.log( "  iframe.style.width " + iframe.style.width)  
    // console.log("   ----------- html url ", this.url)
  },
  methods: {
    // info(doc){
    //   console.log("   doc", doc)
    //   console.log("   scrollHeight", doc.documentElement.scrollHeight)
    //   console.log("   scrollWidth", doc.documentElement.scrollWidth)
    // },
  },
};
</script>

<style scoped lang="less">
  .tool-html-frame {
    // width: 100%;
    // width: 1100px;
    // height: 100%;
    background-color: #FFF;
    padding-bottom: 30px;
    #hframe {
      // border: 1px solid #0f0;
      transform-origin: 0 0;
      transform: scale(0.93, 0.93);
    }
    .loading {   
      font-size: 24px;
      height: 300px;
      // border: 1px solid rgb(16, 16, 219);
      text-align:center;
      transition-property:height;
      transition-duration:3s;
      transition-delay:0s;
      .describe {
        padding: 100px;
      }
    }
    .loaded {
      height: 0px; 
    }
  }

</style>
