<template>
  <!-- 信息公告代码 -->
  <div class="detailbox">
    <!--
    <div id="homebox" class="crumb">
      <div id="pagebox">
        <crumbs :list="crumblist" />
      </div>
    </div>
     -->
    <html-frame :url = "toolTestUrl" v-if="loaded" />
    <div v-else> 正在加载数据</div>
  </div>
</template>

<script>

// import crumbs from "@/components/all/crumbs"; //引入导航栏组件

import htmlFrame from "@/components/all/toolHtmlFrame"; //引入尾部组件

export default {
  name: 'ToolTestDetail',
  components: {
    htmlFrame,
    // crumbs, //引入导航栏组件
  },

  data() {
    return {
      loaded:false,
      uid:'',
      tool: {},
      toolTestUrl: '',
      id: '',
      title: '',
    }
  },
  
  created() {
    let checkLogin = this.until.checkLogin();
    // console.log(" check login ", checkLogin)
    if(!checkLogin) {
      // console.log(" !checkLogin  , jumpt /tologin ", !checkLogin)
      this.until.jump.call(this,'/tologin', "push", {title:"在线检测工具"});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.uid = this.until.getUserInfo().id
      this.init()
      this.getdata()
    }
    // this.init()
    // this.getdata();
    console.log(" ====== uid:", this.uid)
    console.log(" tool url ", this.toolUrl)
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   standard id " ,   this.id)
    },
    getdata() {
      this.$api.getToolTestDetail(this.id).then((res) => {
        console.log( res.data)
        this.tool = res.data;
        this.title = this.tool.name
        this.toolTestUrl = this.tool.detailUrl
        this.loaded = true
        console.log("     toolTestUrl :",this.toolTestUrl)
      });
    },
  },
};
</script>
